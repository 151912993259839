'use client'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ComponentProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaEye, FaEyeSlash, FaExclamationCircle } from 'react-icons/fa'
import { FiLoader } from 'react-icons/fi'
import useTranslation from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'
import Label from '@/ui/shadcn/label'

interface Props extends ComponentProps<'input'> {
  showPasswordToggle?: boolean
  error?: string
  label?: string
  placeholder?: string
  helperInfoNode?: React.ReactNode
  showSkeleton?: boolean
  maxLength?: number
  value?: string
  className?: string
  setAutoFocus?: (focused: boolean) => void
  defaultShowPassword?: boolean
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  {
    type = 'text',
    showPasswordToggle,
    error,
    label,
    helperInfoNode,
    showSkeleton = false,
    maxLength,
    onChange,
    className,
    required = true,
    defaultShowPassword = false,
    ...props
  },
  ref
) {
  const { t } = useTranslation('validations')
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [charCount, setCharCount] = useState(0)

  useImperativeHandle(ref, () => inputRef.current!)

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setCharCount(props.value.length)
    }
  }, [props, props.value])

  useEffect(() => {
    if (props?.autoFocus) {
      inputRef?.current?.focus()
      props?.setAutoFocus?.(false)
    }
  }, [props, props?.autoFocus, ref])

  useEffect(() => {
    setShowPassword(defaultShowPassword)
  }, [defaultShowPassword])

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    if (!maxLength || text.length <= maxLength) {
      setCharCount(text.length)
      onChange?.(event)
    }
  }

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        error && 'border-error-500 hover:border-error-500'
      )}
    >
      <Label htmlFor={props.id}>
        {label}
        {!required && label && (
          <span className="text-xs font-light leading-3">({t?.optional})</span>
        )}
        {helperInfoNode && helperInfoNode}
      </Label>
      <input
        className={cn(
          'bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight block outline-none',

          props.disabled
            ? 'opacity-50 cursor-not-allowed hover:border-gray-300 focus:border-gray-300'
            : '',
          error
            ? 'border-error-500 dark:border-error-500 focus:border-error-500 dark:focus:border-error-500 focus:ring-error-500 dark:focus:ring-error-500 hover:border-error-500 dark:hover:border-error-500'
            : 'hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700',
          className
        )}
        type={showPassword ? 'text' : type}
        ref={inputRef}
        onChange={handleChange}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        {...props}
      />
      {showPasswordToggle && !props.disabled && (
        <div
          className={`absolute bottom-2.5 right-0 flex items-center pr-3 cursor-pointer ${
            props.disabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={toggleShowPassword}
        >
          {showPassword ? (
            <FaEye className="w-5 h-5 text-gray-400" />
          ) : (
            <FaEyeSlash className="w-5 h-5 text-gray-400" />
          )}
        </div>
      )}
      {showSkeleton && (
        <div className="absolute bottom-0 right-0 flex items-center py-3 pr-3 pointer-events-none">
          <FiLoader
            className="w-5 h-5 text-primary"
            style={{ animation: 'spin 1s linear infinite' }}
            aria-hidden="true"
          />
        </div>
      )}
      {error && !showPasswordToggle && (
        <div className="absolute bottom-0 right-0 flex items-center py-3 pr-3 pointer-events-none">
          <FaExclamationCircle
            className="w-4 h-4 text-error-500"
            aria-hidden="true"
          />
        </div>
      )}
      {maxLength && (
        <div className="absolute top-0 right-0 pt-1 text-xs text-gray-500">
          {charCount}/{maxLength}
        </div>
      )}
    </div>
  )
})

export default Input
