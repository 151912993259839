'use client'

import * as React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, CaretSortIcon } from '@radix-ui/react-icons'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import { handlePlural, normalizeText } from '@/utils/text'
import { useLocale } from '@/providers/Locale'
import { ScrollArea } from '../../shadcn/scrollArea'
import Typography from '../Typography'
import { cn } from '@/lib/utils'
import Label from '@/ui/shadcn/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/ui/shadcn/popover'

export type OptionType = {
  label: string
  value: string
  icon?: React.ComponentType<{ className?: string }>
}

type Props = {
  id?: string
  name: string
  options: OptionType[]
  value?: string
  defaultValue?: string
  defaultMenuIsOpen?: boolean
  onSelected: (option: string) => void
  placeholder?: string
  isLoading?: boolean
  error?: string
  label?: string
  showSkeleton?: boolean
  disabled?: boolean
  classNames?: {
    container?: string
    option?: string
    searchInput?: string
  }
}

const SelectSearch: React.FC<Props> = ({
  name,
  error,
  label,
  options,
  value,
  defaultValue,
  onSelected,
  placeholder,
  isLoading,
  showSkeleton = false,
  disabled = false,
  defaultMenuIsOpen = false,
  classNames
}) => {
  const { t: tForm } = useTranslation('form')
  const { lang } = useLocale()
  const t = tForm?.selectSearch
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(
    () => defaultMenuIsOpen
  )
  const [valueSearch, setValueSearch] = React.useState<string>('')
  const [triggerWidth, setTriggerWidth] = React.useState<number>(0)

  const triggerRef = React.useRef<HTMLButtonElement>(null)
  const inputSearchRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth)
    }
  }, [isPopoverOpen])

  const filteredOptions = React.useMemo(
    () =>
      !valueSearch
        ? options
        : options.filter((option) => {
            const normalizedSearch = handlePlural(
              normalizeText(valueSearch),
              lang
            )
            const normalizedOptionLabel = handlePlural(
              normalizeText(option.label),
              lang
            )
            const normalizedOptionValue = handlePlural(
              normalizeText(option.value),
              lang
            )
            return (
              normalizedOptionLabel.includes(normalizedSearch) ||
              normalizedOptionValue.includes(normalizedSearch)
            )
          }),
    [lang, options, valueSearch]
  )

  const handleSelected = React.useCallback(
    (option: OptionType) => {
      // setSelectedOption(option)
      onSelected(option?.value)
      setIsPopoverOpen(false)
      setValueSearch('')
    },
    [onSelected]
  )

  const handleOpenPopover = React.useCallback(() => {
    if (disabled) return
    setIsPopoverOpen(true)
    inputSearchRef.current?.focus()
  }, [disabled])

  const handleClosePopover = React.useCallback(() => {
    setIsPopoverOpen(false)
    setValueSearch('')
  }, [])

  const isEmpty = React.useMemo(
    () => filteredOptions.length === 0 && !isLoading,
    [isLoading, filteredOptions.length]
  )

  const selectedOption = React.useMemo(
    () => options?.find((option) => option.value === value),
    [options, value]
  )

  if (showSkeleton) {
    return (
      <div
        className={cn(
          'flex w-full',
          label && 'flex-col gap-1',
          classNames?.container
        )}
      >
        {label && <div className="w-24 h-3 md:h-4 skeleton" />}
        <div className="w-full h-10 min-w-44 skeleton" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        classNames?.container,
        error && 'border-error-500 hover:border-error-500'
      )}
    >
      {label && (
        <Label onClick={handleOpenPopover} htmlFor={name}>
          {label}
        </Label>
      )}
      <Popover
        open={isPopoverOpen}
        onOpenChange={(open) => {
          if (open) {
            handleOpenPopover()
          } else {
            handleClosePopover()
          }
        }}
        modal={true}
      >
        <PopoverTrigger asChild>
          <button
            ref={triggerRef}
            className={cn(
              'flex items-center gap-2 bg-accent-100 text-accent-900 dark:bg-accent-500 ring-0 border border-gray-300 w-full text-sm px-[13px] min-h-10 h-auto rounded-md shadow-sm font-normal leading-tight outline-none py-2',
              error ? 'border-error-500' : '',
              disabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700'
            )}
            type="button"
            onClick={handleOpenPopover}
          >
            <div className="flex items-center justify-between flex-1 w-full">
              <div className="flex items-center w-full gap-2 text-ellipsis">
                {selectedOption?.label ? (
                  <Typography
                    variant="text-sm-regular"
                    className="w-full truncate font-body"
                  >
                    {selectedOption?.label}
                  </Typography>
                ) : (
                  <span className="text-sm font-normal leading-tight text-gray-400">
                    {t?.placeholder || placeholder}
                  </span>
                )}
              </div>
              <CaretSortIcon className="w-4 h-4 opacity-50" />
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 bg-white border border-gray-300 rounded-md shadow-lg z-100 ring-1 ring-gray-200 focus:outline-none dark:bg-accent-500"
          align="start"
          side="bottom"
          style={{ width: triggerWidth }}
          onEscapeKeyDown={handleClosePopover}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <div className="flex items-center border-b border-gray-200 h-9">
            <MagnifyingGlassIcon
              className="absolute w-4 h-4 text-gray-400 pointer-events-none left-3"
              aria-hidden="true"
            />
            <input
              type="text"
              className={cn(
                'w-full h-full pl-9 text-sm border-t-0 border-b border-l-0 border-r-0 rounded-t-md outline-none border-b-gray-200 focus:ring-0 ring-0 hover:border-b-gray-200 focus:border-b-gray-200 placeholder:text-gray-400 dark:bg-accent-500',
                classNames?.searchInput
              )}
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target.value)}
              placeholder={t?.placeholderSearch}
              ref={inputSearchRef}
            />
          </div>
          <ScrollArea key={valueSearch} className="flex flex-col py-1 max-h-60">
            {isLoading && (
              <div className="flex flex-col gap-[2px] px-1">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    className="flex items-center gap-1 py-1.5 px-3"
                    key={index}
                  >
                    <div className="h-4 w-28 skeleton" />
                  </div>
                ))}
              </div>
            )}
            {filteredOptions?.map((option) => {
              const isSelected = selectedOption?.value === option?.value

              return (
                <label
                  key={option.value}
                  htmlFor={option.value}
                  className={cn(
                    'flex items-center justify-between w-full py-1.5 px-3 cursor-pointer hover:bg-gray-50 hover:text-secondary-700 focus:bg-accent focus:text-accent-foreground text-sm',
                    isSelected && 'text-secondary-700 bg-gray-50',
                    classNames?.option
                  )}
                  onClick={() => handleSelected(option)}
                >
                  {option.label}
                  <CheckIcon
                    className={cn(
                      'mr-2 h-4 w-4',
                      isSelected ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </label>
              )
            })}
            {isEmpty && (
              <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
                <div className="relative w-full h-20 max-w-32">
                  <Image
                    src="/assets/icons/empty-state.svg"
                    alt="Folder Dashboard"
                    fill
                    style={{ objectFit: 'contain' }}
                  />
                </div>
                <Typography variant="text-sm-regular" className="text-center">
                  {t?.emptyState}
                </Typography>
              </div>
            )}
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  )
}

SelectSearch.displayName = 'SelectSearch'

export default SelectSearch
