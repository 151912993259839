'use client'

import React, { useEffect, useState } from 'react'
import { enUS, ptBR } from 'date-fns/locale'
import { utcToZonedTime, format as formatDate } from 'date-fns-tz'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import useTranslation from '@/hooks/useTranslation'
import { useLocale } from '@/providers/Locale'
import { cn } from '@/lib/utils'
import Label from '@/ui/shadcn/label'

export type DatePickerProps = {
  format?: string
  onChange?: (
    date: Date | [Date, Date] | null,
    dateUTC?: string | [string, string] | null
  ) => void
  label?: string
  name?: string
  helperInfoNode?: React.ReactNode
  allowPastDates?: boolean
  onlyDate?: boolean
  onlyTime?: boolean
  noInitialDate?: boolean
  nextDayStart?: boolean
  daysAfterToday?: number
  defaultValue?: Date | null
  error?: string
  placeholder?: string
  onSelected?: (date: Date) => void
  showSkeleton?: boolean
}

const DatePicker: React.FC<
  DatePickerProps & Omit<ReactDatePickerProps, 'onChange'>
> = ({
  format = 'Pp',
  onChange,
  label,
  name,
  helperInfoNode,
  allowPastDates = true,
  onlyDate = false,
  onlyTime = false,
  noInitialDate = false,
  nextDayStart = false,
  daysAfterToday,
  defaultValue,
  error,
  required = true,
  placeholder,
  onSelected,
  showSkeleton = false,
  ...props
}) => {
  const [startDate, setStartDate] = useState<Date | null>(() => {
    if (noInitialDate) return null
    if (defaultValue) return defaultValue
    const now = new Date()

    if (nextDayStart) {
      now.setDate(now.getDate() + 1)
      now.setHours(now.getHours(), 0, 0, 0)
    }

    if (daysAfterToday !== undefined) {
      const valueDate = now.getDate() + daysAfterToday
      now.setDate(valueDate)
    }
    return now
  })
  const { lang } = useLocale()
  const { t: tForm } = useTranslation('form')
  const t = tForm?.datePicker

  const locale = {
    EnUs: enUS,
    PtBr: ptBR
  }[lang]

  const isToday = (date: Date | null) => {
    if (!date) return false
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const getTimes = () => {
    if (!allowPastDates && isToday(startDate)) {
      const now = new Date()
      const minTime = new Date(now.getTime() + 15 * 60000)
      const maxTime = new Date(now.setHours(23, 45, 0, 0))
      return { minTime, maxTime }
    }
    return { minTime: undefined, maxTime: undefined }
  }

  const { minTime, maxTime } = getTimes()

  useEffect(() => {
    if (onChange && startDate) {
      const utcDate = utcToZonedTime(startDate, 'Etc/UTC')
      const dateUTC = formatDate(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
        timeZone: 'Etc/UTC'
      })
      onChange(startDate, dateUTC)
    }
  }, [onChange, startDate])

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        error && 'border-error-500 hover:border-error-500'
      )}
    >
      {label && (
        <Label htmlFor={name}>
          {label}
          {!required && label && (
            <span className="text-xs font-light leading-3">
              ({t?.optional})
            </span>
          )}
          {helperInfoNode && helperInfoNode}
        </Label>
      )}
      <ReactDatePicker
        selected={startDate}
        onChange={(date: Date) => {
          setStartDate(date)
          onSelected?.(date)
          if (onChange) {
            onChange(date)
          }
        }}
        locale={locale}
        showTimeSelect={onlyTime || !onlyDate}
        showTimeSelectOnly={onlyTime}
        timeFormat="p"
        timeIntervals={15}
        dateFormat={format}
        timeCaption={t?.time}
        minDate={allowPastDates ? undefined : new Date()}
        minTime={minTime}
        maxTime={maxTime}
        className={cn(
          'border border-gray-300 bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight block outline-none',
          error
            ? 'border-error-500 dark:border-error-500 focus:border-error-500 dark:focus:border-error-500 focus:ring-error-500 dark:focus:ring-error-500 hover:border-error-500 dark:hover:border-error-500'
            : 'focus:ring-0 focus:ring-primary-700 focus:border-primary-700 hover:border hover:border-primary-200'
        )}
        wrapperClassName="w-full"
        placeholderText={placeholder}
        {...props}
      />
      {/* {error && (
        <div className="absolute bottom-0 right-0 flex items-center py-3 pr-3 pointer-events-none">
          <FaExclamationCircle
            className="w-4 h-4 text-error-500"
            aria-hidden="true"
          />
        </div>
      )} */}
    </div>
  )
}

export default DatePicker
