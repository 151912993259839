'use client'

import { useCallback, useEffect, useState, memo } from 'react'
import useTranslation from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'
import Avatar from '@/ui/atoms/Avatar'
import Label from '@/ui/shadcn/label'
import * as SelectShadcn from '@/ui/shadcn/select'

export type SelectOption = {
  label: string
  value: string
  avatar?: string | boolean
  disabled?: boolean
}

export type SelectGroupOption = {
  label: string
  options: SelectOption[]
}

export type SelectProps = {
  id?: string
  name?: string
  error?: string
  size?: 'sm' | 'md'
  className?: string
  value: string | null
  onChange: (value: any) => void
  onBlur?: () => void
  options: SelectOption[] | SelectGroupOption[]
  placeholder?: string
  disabled?: boolean
  menuPlacement?: 'top' | 'bottom'
  showSkeleton?: boolean
  timeoutSkeleton?: number
  label?: string
  inline?: boolean
  fullWidth?: boolean
  required?: boolean
  helperInfoNode?: React.ReactNode
}

const renderOptions = (
  options: SelectOption[] | SelectGroupOption[],
  isDisabled = false
) => {
  if (!options) return null
  return options.map((item, id) => {
    if ('options' in item) {
      return (
        <SelectShadcn.SelectGroup
          key={`${item.label}-${id}`}
          className="py-1.5"
        >
          {item.label && (
            <SelectShadcn.SelectLabel>{item.label}</SelectShadcn.SelectLabel>
          )}
          {item.options.map((option, id) => (
            <SelectShadcn.SelectItem
              key={`${option.value}-${id}`}
              value={option.value}
              className={cn('py-1.5 cursor-pointer', {
                'focus:bg-gray-50 focus:text-secondary-700': !isDisabled,
                'text-gray-500 bg-gray-200 cursor-not-allowed': isDisabled
              })}
            >
              <div className="flex items-center gap-3">
                {option.avatar && option.label && (
                  <Avatar
                    name={option.label}
                    imgSrc={
                      typeof option.avatar !== 'boolean'
                        ? option.avatar
                        : undefined
                    }
                    size="sm"
                  />
                )}
                {option.label}
              </div>
            </SelectShadcn.SelectItem>
          ))}
        </SelectShadcn.SelectGroup>
      )
    } else {
      const option = item as SelectOption
      return (
        <SelectShadcn.SelectItem
          key={`${option.value}-${id}`}
          value={option.value}
          className={cn('py-1.5 cursor-pointer', {
            'focus:bg-gray-50 focus:text-secondary-700': !isDisabled,
            'text-gray-500 bg-gray-200 cursor-not-allowed': isDisabled
          })}
          disabled={option.disabled}
        >
          <div className="flex items-center gap-3">
            {option.avatar && (
              <Avatar
                name={option.label}
                imgSrc={
                  typeof option.avatar !== 'boolean' ? option.avatar : undefined
                }
                size="sm"
              />
            )}
            {option.label}
          </div>
        </SelectShadcn.SelectItem>
      )
    }
  })
}

const Select: React.FC<SelectProps> = ({
  error,
  className,
  size = 'md',
  value,
  onChange,
  options,
  placeholder,
  disabled = false,
  menuPlacement = 'bottom',
  inline,
  showSkeleton,
  timeoutSkeleton,
  helperInfoNode,
  required = true,
  onBlur,
  ...props
}) => {
  const { t } = useTranslation(['form', 'validations'], true)
  const [valueState, setValueState] = useState<string | undefined>(
    value || undefined
  )
  const [key, setKey] = useState(+new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!!value && value !== valueState) {
      setValueState(value || undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoading(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const handleChange = useCallback(
    (newValue: string) => {
      const updatedValue = newValue === 'none' ? undefined : newValue
      onChange(updatedValue)
      setValueState(updatedValue)
      setKey(+new Date())
    },
    [onChange]
  )

  if (showSkeleton || isLoading) {
    const labelClass = size === 'sm' ? 'w-16 h-3' : 'w-24 h-4'
    const selectClass = size === 'sm' ? 'w-16 h-8' : 'w-full min-w-44 h-10'
    return (
      <div
        className={cn(
          'flex w-full gap-1',
          inline ? 'flex-row items-center' : 'flex-col',
          className
        )}
      >
        <div className={cn('skeleton', labelClass)} />
        <div className={cn('skeleton', selectClass)} />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        props.fullWidth ? 'max-w-full' : 'w-full md:w-56',
        error && 'border-error-500 hover:border-error-500',
        inline ? 'flex-row items-center justify-start' : 'flex-col',
        className
      )}
    >
      {props.label && (
        <Label htmlFor={props.id}>
          {props.label}
          {!required && (
            <span className="text-xs font-light leading-3">
              ({t?.optional})
            </span>
          )}
          {helperInfoNode}
        </Label>
      )}
      <div className="relative">
        <SelectShadcn.Select
          onValueChange={(newValue) => {
            if (!newValue) return
            handleChange(newValue)
          }}
          value={valueState}
          key={key}
          disabled={disabled}
        >
          <SelectShadcn.SelectTrigger
            id={props.id}
            className={cn(
              'w-full min-w-0 flex item-center gap-2',
              size === 'sm' ? 'h-8' : 'h-10',
              error
                ? 'border-error-500 hover:border-error-500 text-error-500'
                : 'border-gray-300',
              disabled && 'text-gray-500 cursor-not-allowed',
              className
            )}
            aria-disabled={disabled}
            onBlur={onBlur}
          >
            <SelectShadcn.SelectValue placeholder={placeholder} />
          </SelectShadcn.SelectTrigger>
          <SelectShadcn.SelectContent side={menuPlacement}>
            {renderOptions(options, disabled)}
          </SelectShadcn.SelectContent>
        </SelectShadcn.Select>
        {error && (
          <div className="absolute left-0 mt-1 text-xs text-error-500">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Select)
