'use client'

import { ComponentProps, forwardRef, useEffect, useState } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import useTranslation from '@/hooks/useTranslation'
import { cn } from '@/lib/utils'
import Label from '@/ui/shadcn/label'

interface TextareaProps extends ComponentProps<'textarea'> {
  error?: string
  label?: string
  placeholder?: string
  maxLength?: number
  helperInfoNode?: React.ReactNode
  value?: string
  showSkeleton?: boolean
  className?: string
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    {
      error,
      label,
      maxLength,
      onChange,
      helperInfoNode,
      showSkeleton,
      className,
      required = true,
      ...props
    },
    ref
  ) {
    const { t } = useTranslation('validations')
    const [charCount, setCharCount] = useState(0)

    useEffect(() => {
      if (props.value && props.value.length > 0) {
        setCharCount(props.value.length)
      }
    }, [props, props.value])

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const text = event.target.value
      if (!maxLength || text.length <= maxLength) {
        setCharCount(text.length)
        onChange?.(event)
      }
    }

    if (showSkeleton) {
      return (
        <div className="flex flex-col w-full gap-1">
          <div className="w-24 h-3 md:h-4 skeleton" />
          <div className="w-full h-60 skeleton" />
        </div>
      )
    }

    return (
      <div
        className={cn(
          'relative w-full flex flex-col gap-1',
          error && 'border-error-500 hover:border-error-500'
        )}
      >
        {label && (
          <Label htmlFor={props.id}>
            {label}
            {!required && label && (
              <span className="text-xs font-light leading-3">
                ({t?.optional})
              </span>
            )}
            {helperInfoNode && helperInfoNode}
          </Label>
        )}

        <textarea
          className={cn(
            'bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 border border-gray-300 w-full text-sm px-[13px] h-32 rounded-md shadow-sm font-normal leading-tight block outline-none sm:text-sm sm:leading-6',
            error
              ? 'border-error-500 dark:border-error-500 focus:border-error-500 dark:focus:border-error-500 focus:ring-error-500 dark:focus:ring-error-500 hover:border-error-500 dark:hover:border-error-500'
              : 'hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700',
            props.disabled
              ? 'opacity-50 cursor-not-allowed hover:border-gray-300 focus:border-gray-300'
              : '',
            className
          )}
          ref={ref}
          onChange={handleChange}
          {...props}
        />
        {error && (
          <div className="absolute bottom-0 flex items-center py-3 pr-5 pointer-events-none right-1">
            <FaExclamationCircle
              className="w-4 h-4 text-error-500"
              aria-hidden="true"
            />
          </div>
        )}
        {maxLength && (
          <div className="absolute top-0 right-0 text-xs text-gray-500">
            {charCount}/{maxLength}
          </div>
        )}
      </div>
    )
  }
)

export default Textarea
