'use client'

import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import * as InputOTP from '@/ui/shadcn/inputOTP'
import Label from '@/ui/shadcn/label'

const tokenVariants = cva(
  'text-center border border-gray-300 rounded text-accent-900 dark:bg-accent-500 bg-accent-100 focus:outline-none hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700 first:rounded-l last:rounded-r',
  {
    variants: {
      size: {
        sm: 'w-10 h-10 sm:h-12 sm:w-12',
        default: 'w-13 h-13 sm:h-15 sm:w-15'
      }
    },
    defaultVariants: {
      size: 'default'
    }
  }
)

type Props = VariantProps<typeof tokenVariants> & {
  length?: number
  value: string
  onChange: (value: string) => void
  autoFocus?: boolean
  label?: string
  id?: string
  name?: string
  classNameContainer?: string
}

const TokenInput: React.FC<Props> = ({
  length = 6,
  value,
  onChange,
  autoFocus = false,
  label,
  size,
  classNameContainer,
  ...props
}) => {
  const content = (
    <InputOTP.InputOTP
      maxLength={6}
      value={value}
      onChange={(value) => onChange(value)}
      autoFocus={autoFocus}
    >
      <InputOTP.InputOTPGroup className="justify-between w-full">
        {Array.from({ length }).map((_, index) => (
          <InputOTP.InputOTPSlot
            index={index}
            key={index}
            className={cn(tokenVariants({ size }))}
          />
        ))}
      </InputOTP.InputOTPGroup>
    </InputOTP.InputOTP>
  )

  if (label) {
    return (
      <div className={cn('relative w-full flex justify-end flex-col gap-1')}>
        <Label htmlFor={props.id} className={classNameContainer}>
          {label}
        </Label>
        {content}
      </div>
    )
  }

  return content
}

export default TokenInput
